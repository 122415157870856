











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import url from '@/lib/url';
import ListBase from '@/lib/listbase';
import Election from '@/store/entities/elections/election';
//import Applicants from '@/views/elections/election/applicants.vue';
//import Questions from '@/views/elections/election/questions.vue';
import Results from '@/views/elections/election/results.vue';

class PageElectionRequest extends PageRequest {
    election: number = null;
}

@Component({
    components: { FormBase, VWidget, Results },
})

export default class ElecitionForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    election: Election = new Election();
    pagerequest: PageElectionRequest = new PageElectionRequest();
    modalShow: boolean = true;
    sState: any = Util.abp.list.getCodeList('E_ELECTION');
    
    filterItems = [
        this.gItem({ type: 'object', prop: 'election', label: 'Eleccion', itemText: 'description', itemSubTitle: 'notes', itemChip: 'number', itemChipColor: 'state_color', size: 8, filterParam: 'state_id', filterVal: [this.sState.CE], hideDetails: true, onchange: this.loadData }),
    ]

    /***** computed methods *****/
    get loading() {
        return this.$store.state.election.loading;
    };

    get getLoading() {
        return this.$store.state.election.getLoading;
    };
    
    getItem(id) {
        return Util.abp.list.getItem(id);
    }

    getInitials(string) {
        return Util.getInitials(string);
    }
    
    getWinners() {
        let winners = this.election.applicants.filter(w => w.winner).map(m => m.name).join(',');
        return winners;
    }


    /***** FUNCIONES DE INICIO *****/
    async loadData(id) {
        let model = await this.$store.dispatch({
            type: 'election/get',
            id: id
        });
        this.$store.commit('election/view', model);
        this.election = Util.extend(true, {}, this.$store.state.election.editModel);
    }
    
    async mounted() {
        
        this.$store.dispatch({
            type: 'election/getAllActive'
        });
    }
}
